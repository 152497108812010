<template>
  <a-modal :width="500" :class="$style.container" v-model="isShow" :title="(info.id ? '编辑' : '新增') + '品牌'"
  @ok="determine" @cancel="cancel" :maskClosable="false">
    <a-form-model ref="form" :rules="rules" :class="$style.form__ct" class="al-form" :label-col="{ style: 'width: 100px' }" :wrapper-col="{ style: 'width: 300px' }" layout="inline" :model="form">
      <a-row type="flex" justify="space-between">
        <a-form-model-item label="品牌名称:" prop="name" style="margin-bottom:0">
          <a-input v-model="form.name" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="LOGO:" prop="iconUrl" style="margin-bottom:0">
        <ImageManage v-model="form.iconUrl" @change="$refs.form.validateField(['iconUrl'])" :square="false" :size="1024*1024*2" :showWidth="true">
            <div slot="extra">建议上传图片300*300分辨率，支持jpg、png格式，大小不超过60KB</div>
        </ImageManage>
          <!-- <a-input v-model="form.iconUrl" placeholder="请输入" style="display:none"/>
          <a-upload name="file" list-type="picture-card" class="avatar-uploader avatar-uploader1"
            :show-upload-list="false" :action="$consts.uploadFileUrl"
            :headers="$store.state.header" :before-upload="(e)=>{beforeUpload(e,'img1')}" @change="(e)=>{handleChange(e,'img1')}">
            <a-avatar shape="square" v-if="form.iconUrl" :src="form.iconUrl" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload> -->
        </a-form-model-item>
        <a-form-model-item label="品牌页大图:" prop="imgUrl" style="margin-bottom:0">
            <ImageManage v-model="form.imgUrl" @change="$refs.form.validateField(['imgUrl'])" :square="false" :size="1024*1024*2" :showWidth="true">
                <div slot="extra">建议上传图片720*540分辨率，支持jpg、png格式，大小不超过200KB</div>
            </ImageManage>
          <!-- <a-input v-model="form.imgUrl" placeholder="请输入" style="display:none"/>
          <a-upload name="file" list-type="picture-card" class="avatar-uploader avatar-uploader1"
            :show-upload-list="false" :action="$consts.uploadFileUrl"
            :headers="$store.state.header" :before-upload="(e)=>{beforeUpload(e,'img2')}" @change="(e)=>{handleChange(e,'img2')}">
            <a-avatar shape="square" v-if="form.imgUrl" :src="form.imgUrl" />
            <div v-else>
              <a-icon :type="loading ? 'loading' : 'plus'" />
              <div class="ant-upload-text">
                上传
              </div>
            </div>
          </a-upload>
          <div slot="extra">建议上传图片720*540分辨率，支持jpg、png格式，大小不超过200KB</div> -->

        </a-form-model-item>
        <!-- <a-form-model-item label="简介:" prop="intro" style="margin-bottom:0">
          <a-textarea type="" v-model="form.intro" placeholder="请输入" />
        </a-form-model-item> -->
        <a-form-model-item label="简介:" prop="intro" style="margin-bottom:0">
          <a-textarea type="" auto-size v-model="form.intro" placeholder="请输入" />
        </a-form-model-item>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
// import E from 'wangeditor-antd'
import ImageManage from '@/components/imageManage/index.vue'
export default {
  components: { ImageManage },
  data () {
    return {
      content: '',
      contentVis: false,
      overSize1: true,
      overSize2: true,
      loading: false,
      isShow: false, // 是否显示弹窗
      info: {}, // 详情回显
      form: {
      },
      rules: {
        name: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ],
        iconUrl: [
          { required: true, message: 'LOGO不能为空', trigger: 'blur' }
        ],
        imgUrl: [
          { required: true, message: '首页大图不能为空', trigger: 'blur' }
        ],
        intro: [
          { required: true, message: '简介不能为空', trigger: 'blur' },
          { min: 10, max: 1000, message: '简介长度10到1000个字符', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
  },
  watch: {
    isShow (val) {
      if (val) {
        console.log('------', this.info)
        if (this.info.id) {
          console.log(this.info.id)
          this.getGoBrandInfo(this.info.id)
        }
      } else {
        // this.getGoBrandInfo()
      }
    }
  },
  created () {
  },
  methods: {
    async getGoBrandInfo (id) {
      const res = await this.$store.dispatch('http', {
        api: 'getGoBrandInfo',
        query: {
          id: id
        }
      })
      this.form = res
      console.log(res)
    },
    beforeUpload (file, str) {
      console.log(file, str, 'file str 传过来两个图')
      this.overSize1 = false
      this.overSize2 = false
      let isOverSize
      isOverSize = str === 'img1' ? file.size / 1024 < 60
        : str === 'img2' ? file.size / 1024 < 200 : ''
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$store.dispatch('showToast', { type: 'warning', message: '图片格式不正确' })
      }

      if (!isOverSize) {
        this.$store.dispatch('showToast', { type: 'warning', message: '图片超过了大小,请上传正确大小的图片' })
        str === 'img1' ? this.overSize1 = true
          : str === 'img2' ? this.overSize2 = true : console.log('数据错误')
      }
      // return isJpgOrPng && isOverSize
      return false
    },
    async handleChange (info, str) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        str === 'img1' && !this.overSize1 ? this.form.iconUrl = info.file.response.result
          : str === 'img2' && !this.overSize2 ? this.form.imgUrl = info.file.response.result : console.log('有错误')
        this.loading = false
      }
    },
    cancel () {
      console.log('cancel')
      this.form = {
      }
    },
    determine (e) {
      console.log('aaaaaa')
      console.log('MMMMMMM', this.form)
      delete this.form.gmtCreate
      delete this.form.gmtModified
      let apii = ''
      if (this.form.id) {
        apii = 'goBrandSaveOrUpdate'
      } else {
        apii = 'goBrandSaveOrAdd'
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          let api = apii
          this.$store.dispatch('http', {
            api,
            params: this.form,
            success: () => {
              this.cancel()
              this.$store.dispatch('showToast', { message: (this.info.id ? '编辑' : '新增') + '成功~' })
              this.isShow = false
              this.$emit('save')
            }
          })
        }
      })
    }
  }
}
</script>

<style>
.avatar-uploader > .ant-upload {
  width: 120px;
  height: 120px;
}
.ant-upload-select-picture-card{
  margin:0 !important;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
.avatar-uploader1 .ant-avatar{
  width:100px;
  height: 100px;
  margin: 0;
}
.diseaseNames_box{
  display: flex;
  flex-wrap: wrap;
}
.diseaseNames_box .cell{
  padding: 4px 6px;
  line-height: 14px;
  background: #999;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}
.diseaseNames_box .cell .delete{
  position: absolute;
  top: -9px;
  right: -9px;
}
</style>

<style lang="scss" module>
.container {
  width: auto;
}
</style>

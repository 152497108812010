<template>
  <div>
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
      <!-- <a-button @click="showModal" class="add-bt" type="primary">新增</a-button> -->
      <a-button class="add-bt" type="primary" @click="$refs.saveDialog.info = {};$refs.saveDialog.isShow = true">新增</a-button>
    </Breadcrumb>
    <a-form
      ref="formRef"
      layout="inline"
      :model="form"
    >
      <a-form-item>
        <a-input v-model="form.name" placeholder="名称" />
      </a-form-item>

      <a-form-item>
        <a-button type="primary"
                  :loading="isQueryLoading"
                  @click="queryList">查询</a-button>
        <a-button @click="resetForm('form')"
                  style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>
    <a-table :data-source="list"
             bordered
             :rowKey="record => record.id"
             @change="currentChange"
             :columns="columns"
             :pagination="pagination"
             :scroll="{ x:1500 }">
      <span slot="intro" slot-scope="text,record">
        <div class="toolTip">
          <a-tooltip :overlayStyle="{'width':'1000px'}" placement="topLeft">
            <template  slot="title">
              {{record.intro}}
            </template>
            {{record.intro}}
          </a-tooltip>
        </div>
      </span>
      <span slot="logo" slot-scope="text">
        {{text}}
      </span>
      <div slot="logo"
           slot-scope="text,record">
        <a-row type="flex">
          <a-col flex="60px">
            <!-- <a-avatar shape="square" style="width:50px;height:50px;" :src="record.qrcodeUrl" /> -->
            <viewer style="height:50px;">
              <img style="max-width:50px;max-height:50px;"
                   :src="record.iconUrl"
                   alt="">
            </viewer>
          </a-col>
        </a-row>
      </div>
      <span slot="action" slot-scope="text,record">
        <a style="margin-right:20px;" @click="$refs.saveDialog.info = {'id':record.id};$refs.saveDialog.isShow = true">编辑</a>

        <a style="margin-right:20px;" @click='deleteGrand(record.id)' href="javascript:;">删除</a>
      </span>
    </a-table>
    <AddEditBrand ref="saveDialog"
         @save="reloadList"></AddEditBrand>
  </div>
</template>

<script>
import AddEditBrand from './addOrEdit'
import Breadcrumb from '@/components/breadcrumb.vue'
export default {
  components: {
    AddEditBrand, Breadcrumb
  },
  data () {
    return {
      breadList: [
        {
          name: '商品管理',
          url: ''
        },
        {
          name: '品牌管理',
          url: ''
        }
      ],
      visible: false,
      isQueryLoading: false,
      form: {
        id: ''
      },
      formRefuse: {
        id: '',
        failReason: ''
      },
      columns: [
        { title: '品牌名称', width: 150, ellipsis: true, dataIndex: 'name', key: 'name' },
        { title: '品牌编号', width: 150, ellipsis: true, dataIndex: 'id', key: 'id' },
        { title: 'LOGO', width: 100, dataIndex: 'iconUrl', key: 'iconUrl', scopedSlots: { customRender: 'logo' } },
        { title: '品牌介绍', width: 400, dataIndex: 'intro', key: 'intro', scopedSlots: { customRender: 'intro' } },
        { title: '时间', width: 180, dataIndex: 'gmtCreate', ellipsis: true, key: 'gmtCreate' },
        // { title: '商品数量', width: 100, dataIndex: 'qty', key: 'qty' },
        // { title: '排序', dataIndex: 'sort', key: 'sort' },
        { title: '操作', width: 150, fixed: 'right', key: 'action', scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共${total}条记录,第${this.pagination.current}页`
      },
      total: 0,
      list: [],
      selectedRowKeys: []
    }
  },
  created () {
    this.reloadList()
  },
  methods: {
    deleteGrand (id) {
      this.$store.dispatch('showConfirm', {
        message: '你确定要删除这个品牌吗？',
        yes: async _ => {
          this.isLoading = true
          await this.$store.dispatch('http', {
            api: 'deleteGoBrand',
            query: {
              id: id
            },
            success: () => {
              this.isLoading = false
              this.$store.dispatch('showToast', { message: '删除成功' })
              this.reloadList()
            }
          })
        }
      })
    },
    resetForm (formName) {
      this.form = {}
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
        // this.selectedRowKeys = []
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => { }) {
      this.pagination.current = 1
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => { }) {
      console.log(this.form)
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'goBrandPageList',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      this.list = records
    },
    // 分页、排序、筛选变化时触发
    currentChange (val) {
      console.log(val)
      if (this.pagination.current === val.current) {
        this.pagination.current = 1
      } else {
        this.pagination.current = val.current
      }
      this.pagination.pageSize = val.pageSize
      this.loadList()
    },
    // 选中事件
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>

<style lang="scss" scoped>
.toolTip{
 display:-webkit-box;
-webkit-line-clamp:3;
-webkit-box-orient:vertical;
overflow:hidden;
}
.tips{
 color:rgba(24, 144, 255,1);
 background:rgba(230,247,255,1);
 padding:10px 50px;
 border:1px solid rgb(215, 231, 247);
 margin-top: 10px;
 :first-child{
   font-weight: bold;
   font-size: 20px;
 }
}
</style>
